<div class="row">
  <div class="col-12">
      <p>{{'item.edit.move.description' | translate}}</p>
      <div class="row">
          <div class="col-12">
            <div class="card mb-3">
              <div class="card-header">{{'dso-selector.placeholder' | translate: { type: 'dso-selector.placeholder.type.collection' | translate } }}</div>
              <div class="card-body">
                <ds-authorized-collection-selector [types]="COLLECTIONS"
                  (onSelect)="selectDso($event)">
                </ds-authorized-collection-selector>
              </div>
              <div></div>
            </div>
          </div>
      </div>

    <div class="button-row bottom">
      <div class="float-right space-children-mr">
        <button class="btn btn-primary" [disabled]="!canSubmit" (click)="performAction()">
          {{"publish-and-workflow" | translate}}
        </button>
      </div>
    </div>
  </div>
</div>
