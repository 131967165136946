import { Injectable } from '@angular/core';


import { EditItem } from './models/edititem.model';
import { dataService } from '../cache/builders/build-decorators';
import { IdentifiableDataService } from '../data/base/identifiable-data.service';
import { DSOChangeAnalyzer } from '../data/dso-change-analyzer.service';
import { HALEndpointService } from '../shared/hal-endpoint.service';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from 'src/app/shared/notifications/notifications.service';
import { RequestService } from '../data/request.service';
import { RemoteDataBuildService } from '../cache/builders/remote-data-build.service';
import { ObjectCacheService } from '../cache/object-cache.service';
import { Store } from '@ngrx/store';
//import { CoreState } from '../core.reducers';

/**
 * A service that provides methods to make REST requests with edititems endpoint.
 */
@Injectable({
  providedIn: 'root',
})
@dataService(EditItem.type)
export class EditItemDataService extends IdentifiableDataService<EditItem> {
  protected linkPath = 'edititem';

  constructor(
    protected comparator: DSOChangeAnalyzer<EditItem>,
    protected halService: HALEndpointService,
    protected http: HttpClient,
    protected notificationsService: NotificationsService,
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected objectCache: ObjectCacheService,
    //protected store: Store<CoreState>) {
  ){
    super('edititem', requestService, rdbService, objectCache, halService);
  }
}
